<template>
  <div class="dez-accordion" id="page_section">
    <div class="panel" :key="section.id" v-for="section of getSortedItems()">
      <div class="acod-head">
        <h5 class="acod-title">
          <a
            data-toggle="collapse"
            :href="'#sec' + section.id"
            data-parent="#page_section"
            ><i class="fa fa-file"></i> {{ section.title }}</a
          >
        </h5>
      </div>
      <div
        :id="'sec' + section.id"
        class="acod-body collapse"
        data-parent="#page_section"
      >
        <div class="acod-content" v-html="section.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  methods: {
    getSortedItems() {
      let result = [];

      this.sections.map((item) => {
        result.push(item.page_section_id);
      });

      result.sort((a, b) => {
        return a.sort - b.sort;
      });

      return result;
    },
  },
  props: {
    sections: Array,
  },
});
</script>
