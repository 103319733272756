<template>
  <div class="page-content">
    <!-- inner page banner -->
    <div
      class="dez-bnr-inr overlay-black-middle"
      :style="`background-image: url(${getImage(
        section.main_image,
        'x2000y1200'
      )})`"
    >
      <div class="container">
        <div class="dez-bnr-inr-entry">
          <h1 class="text-primary">Sektion</h1>
          <h1 class="text-white">{{ section.title }} / {{ page.title }}</h1>
        </div>
      </div>
    </div>
    <!-- inner page banner END -->
    <!-- Breadcrumb row -->
    <Breadcrumb
      v-bind:data="[
        { label: 'Sektionen', url: '/sections' },
        { label: section.title, url: '/sections/' + section.slug },
        { label: page.title },
      ]"
    />
    <div class="content-area">
      <div class="container">
        <div class="row">
          <!-- Side bar start -->
          <div class="col-lg-4">
            <aside class="side-bar">
              <div
                class="widget icon-bx-wraper bx-style-1 p-a20 widget_services"
              >
                <h5 class="widget-title">{{ section.title }}</h5>
                <SectionNavigation :section="section" team="{}" />
              </div>
            </aside>
          </div>
          <!-- Side bar END -->
          <!-- Left part start -->
          <div class="col-lg-8">
            <div>
              <h1 class="m-b20 m-t0">
                {{ page.title }} |
                <span class="text-primary">{{ section.title }}</span>
              </h1>
              <div class="dez-separator bg-primary"></div>
            </div>
            <div>
              <div v-html="page.content"></div>
            </div>

            <div v-if="page.sections.length">
              <PageSection v-bind:sections="this.page.sections" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { getImage } from "../lib/helper";
import sections from "../lib/api/sections";
import pages from "../lib/api/pages";
import Breadcrumb from "../components/misc/Breadcrumb.vue";
import SectionNavigation from "../components/sections/Navigation.vue";
import PageSection from "../components/misc/PageSection.vue";

export default defineComponent({
  components: {
    Breadcrumb,
    SectionNavigation,
    PageSection,
  },
  methods: {
    getImage,
    fetchSection: async function (id) {
      const sectionsResult = await sections.getSectionBySlug(id);
      this.section = sectionsResult.data.shift();
    },
    fetchPage: async function (id) {
      const pagesResult = await pages.getPageById(id);
      this.page = pagesResult.data.shift();
    },
  },
  data() {
    return {
      section: {
        settings: {
          title: "",
        },
      },
      page: {
        sections: [],
      },
    };
  },
  watch: {
    $route(route) {
      this.fetchSection(route.params.slug);
      this.fetchPage(this.$route.params.page);
    },
  },
  async mounted() {
    this.fetchSection(this.$route.params.slug);
    this.fetchPage(this.$route.params.page);
  },
});
</script>
